// @flow strict

const getIconColor = (name: string) => {
  let iconColor;

  switch (name) {
    case 'twitter':
        iconColor =  '#1da1f2'
        break;
    case 'github':
        iconColor =  '#1da1f2'
        break;
    case 'telegram':
        iconColor =  '#1da1f2'
        break;
    case 'email':
        iconColor =  '#d44b3d'
        break;
    case 'angel':
        iconColor =  'black'
        break;
    case 'producthunt': 
        iconColor =  '#cc4d29'
        break;
    case 'linkedin': 
        iconColor =  '#0077b5'
        break;
    default:
        iconColor =  '#1da1f2'
        break;
  }

  return iconColor;
};

export default getIconColor;
